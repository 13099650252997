<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>
  <div class="not-found-page_content">
    <div>
      <img
        appAssetSrc="assets/svg/common/not-found.svg"
        alt="logo"
      />
      <h2>{{ 'notFoundPage.somethingWentWrong' | translate }}</h2>
      <h3>{{ 'notFoundPage.pageExistsMsg' | translate }}</h3>
      <button
        class="btn btn-primary submit-btn"
        (click)="redirectToDashboard()"
      >
        {{ 'notFoundPage.buttons.goToMainPage' | translate }}
      </button>
    </div>
  </div>
</div>
