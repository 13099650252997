<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="invitation-inactive-page_content">
    <img appAssetSrc="assets/svg/auth/invitation-expired.svg" />
    <h1>{{ 'invitationExpiredPage.title' | translate }}</h1>
    <p innerHtml="{{ 'invitationExpiredPage.invitationExpiredMsg' | translate }}"></p>
  </div>
</div>
