<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <h1>{{ 'connectionInvitationPage.declineModal.title' | translate }}</h1>
  <p>
    {{ 'connectionInvitationPage.declineModal.message' | translate: { companyName: companyName } }}
  </p>

  <textarea
    ngbAutofocus
    [(ngModel)]="reasonText"
    placeholder="{{ 'connectionInvitationPage.declineModal.declineReasonPlaceholder' | translate }}"
  >
  </textarea>

  <div class="buttons-group">
    <button
      class="btn decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [appBtnLoading]="isLoading"
      class="btn btn-primary submit-btn"
      (click)="declineInvitation()"
    >
      {{ 'common.buttons.ok' | translate }}
    </button>
  </div>
</div>
