<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="maintenance-page_content">
    <img
      alt="Maintenance icon"
      appAssetSrc="assets/svg/common/maintenance.svg"
    />
    <h1>{{ 'maintenancePage.title' | translate }}</h1>
    <p innerHtml="{{ 'maintenancePage.message' | translate }}"></p>
    <a
      class="btn btn-primary submit-btn"
      href="https://clovercollab.com"
      target="_blank"
    >
      {{ 'maintenancePage.visitClover' | translate }}
    </a>
  </div>
</div>
