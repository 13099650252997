<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <div class="tfa-modal-content">
    <ng-container *ngTemplateOutlet="isAuthFailed ? authFailed : enterCode"></ng-container>
  </div>
</div>

<ng-template #enterCode>
  <img
    class="tfa-modal-illustration"
    appAssetSrc="assets/svg/settings/phone-confirmation.svg"
  />
  <h1>{{ 'tfaModal.2factorAuth' | translate }}</h1>
  <div class="tfa-modal-text">
    <p>{{ 'tfaModal.thanks' | translate }}</p>
    <p>{{ 'tfaModal.authCodeSent' | translate }}</p>
    <p class="f-bold">{{ maskedPhone }}</p>
  </div>
  <form
    autocomplete="off"
    [formGroup]="tfaForm"
    #ngForm="ngForm"
    (ngSubmit)="signin()"
  >
    <div
      class="form-group"
      [ngClass]="{
        'has-error': ngForm.submitted && tfaForm.get('code').invalid,
      }"
    >
      <label
        class="required"
        for="code"
      >
        {{ 'tfaModal.controls.authCode.label' | translate }}
      </label>
      <input
        ngbAutofocus
        maxlength="6"
        formControlName="code"
        id="code"
        placeholder="{{ 'tfaModal.controls.authCode.placeholder' | translate }}"
      />
      <form-error-messages
        [showErrors]="ngForm.submitted"
        [control]="tfaForm.get('code')"
      >
      </form-error-messages>
    </div>
    <button
      type="submit"
      [appBtnLoading]="isLoading"
      [disabled]="ngForm.submitted && tfaForm.invalid"
      class="btn btn-primary submit-btn"
    >
      {{ 'tfaModal.buttons.secureSignIn' | translate }}
    </button>
  </form>
</ng-template>

<ng-template #authFailed>
  <img
    class="tfa-modal-warning"
    appAssetSrc="assets/svg/auth/tfa-failed.svg"
  />
  <h1 class="color-red">
    {{ 'tfaModal.authFailed' | translate }}
  </h1>
  <div class="tfa-modal-text">
    <p>{{ 'tfaModal.tryAgain' | translate }}</p>
    <p class="f-bold">{{ maskedPhone }}</p>
  </div>
  <button
    [appBtnLoading]="isLoading"
    class="btn btn-warning warning-btn"
    (click)="resendCode()"
  >
    {{ 'tfaModal.buttons.resendCode' | translate }}
  </button>
</ng-template>
