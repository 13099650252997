<div class="modal-body">
  <div class="impersonate-modal_header">
    <p>{{ 'impersonation.impersonateUser' | translate }}</p>
    <button
      (click)="activeModal.dismiss()"
      [appFocusable]
      aria-label="Close"
      class="close-btn"
    >
      <img
        alt="{{ 'common.buttons.close' | translate }}"
        appAssetSrc="assets/svg/common/cross.svg"
      />
    </button>
  </div>

  <div class="impersonate-modal_search">
    <search-input
      (selectionChanged)="onUserSelect($event)"
      [(ngModel)]="searchValue"
      [itemTemplate]="userTemplate"
      [nameFormatFunc]="searchNameFormatFunc"
      [searchFn]="userSearchFunc"
      placeholder="{{ 'impersonation.controls.searchUser.placeholder' | translate }}"
      searchKeyword="userName"
    >
    </search-input>

    <ng-template
      #userTemplate
      let-user
    >
      <div class="impersonate-modal_user-search-item">
        @if (user?.logoUrl) {
          <img
            [src]="user.logoUrl"
            class="mr-1"
            class="impersonate-modal_user-logo"
          />
        }
        @if (!user?.logoUrl) {
          <div class="impersonate-modal_logo-placeholder-small">
            {{ getLogoPlaceholder(user) }}
          </div>
        }
        <span>{{ user.firstName + ' ' + user.lastName }}</span>
      </div>
    </ng-template>

    @if (isLoading) {
      <loader></loader>
    }

    @if (!isLoading && selectedUser) {
      <div class="impersonate-modal_user-info detailed-info">
        <div class="impersonation_user-info_logo">
          @if (selectedUser?.logoUrl) {
            <img [src]="selectedUser.logoUrl" />
          }
          @if (!selectedUser?.logoUrl) {
            <div class="impersonate-modal_logo-placeholder">
              <span>{{ getLogoPlaceholder(selectedUser) }}</span>
            </div>
          }
        </div>
        <div class="detailed-info_columns">
          <div class="detailed-info_column">
            <div>
              <div class="detailed-info_detail-name">
                {{ 'common.labels.name' | translate }}
              </div>
              <div class="detailed-info_detail-value">
                {{ getComposedUserName(selectedUser) }}
              </div>
            </div>
            <div>
              <div class="detailed-info_detail-name">
                {{ 'common.labels.company' | translate }}
              </div>
              <div class="detailed-info_detail-value">
                {{ selectedUser.companyName }}
              </div>
            </div>
          </div>
          <div class="detailed-info_column">
            <div>
              <div class="detailed-info_detail-name">
                {{ 'common.labels.email' | translate }}
              </div>
              <div class="detailed-info_detail-value">
                {{ selectedUser.username }}
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="impersonate-modal_buttons">
    @if (selectedUser) {
      <button
        (click)="clearSearch()"
        [disabled]="isLoading"
        [appFocusable]
        class="btn btn-link"
      >
        {{ 'common.buttons.clearSearch' | translate }}
      </button>
    }
    <button
      (click)="impersonate()"
      [appBtnLoading]="isLoading"
      [disabled]="!selectedUser"
      class="btn btn-primary submit-btn"
    >
      {{ 'impersonation.buttons.impersonate' | translate }}
    </button>
  </div>
</div>
