<div
  [class.header--alternative]="useAlternativeHeader()"
  class="header"
>
  <div
    [style.--side-width.px]="sideWidth"
    class="header__content"
  >
    <div class="header__content-side-container">
      <div
        (ccResize)="headerSideResize($event, HeaderSide.Left)"
        class="header__content-left"
      >
        <div></div>
        @if (showLogo) {
          <div class="header__content-left-logo">
            <img
              [appAssetSrc]="'assets/svg/common/clover-logo.svg'"
              alt="logo"
            />
          </div>
        }
      </div>
    </div>

    <div class="header__content-search">
      <app-global-search [disabled]="!permissions?.Search_GlobalSearch"></app-global-search>
    </div>

    <div class="header__content-side-container">
      <div
        (ccResize)="headerSideResize($event, HeaderSide.Left)"
        class="header__content-right"
      >
        <div class="header__content-right-actions">
          <div
            ngbDropdown
            placement="bottom-right"
          >
            <div
              [class.header__content-right-actions-item--has-unread-notifications]="hasNotifications"
              [appFocusable]
              class="header__content-right-actions-item"
              id="notificationDropdownToggle"
              ngbDropdownToggle
            >
              <i class="icon icon-notification"></i>

              <div
                aria-labelledby="notificationDropdownToggle"
                class="header__content-right-actions-item-dropdown-menu-container"
                ngbDropdownMenu
              >
                <app-notifications-dropdown-menu
                  (hasNotificationsChange)="setHasNotifications($event)"
                  (notificationsToggle)="notificationsToggle()"
                >
                </app-notifications-dropdown-menu>
              </div>
            </div>
          </div>

          <div
            (click)="beaconToggle()"
            [appFocusable]
            class="header__content-right-actions-item"
          >
            <i class="icon icon-help"></i>
          </div>

          <div
            [routerLink]="['/settings']"
            [appFocusable]
            class="header__content-right-actions-item"
          >
            <i class="icon icon-settings"></i>
          </div>
        </div>

        <div class="header__content-right-profile">
          <div
            ngbDropdown
            placement="bottom-right"
          >
            <div
              [appFocusable]
              class="header__content-right-profile-button"
              id="profileDropdownToggle"
              ngbDropdownToggle
            >
              <div class="header__content-right-profile-button-logo-stack">
                @if (currentConnectedUser) {
                  <div class="header__content-right-profile-button-logo-stack-item">
                    <company-logo [company]="currentConnectedUser"></company-logo>
                  </div>
                }

                <div class="header__content-right-profile-button-logo-stack-item">
                  <user-logo
                    [user]="user"
                    hideStatus="true"
                  ></user-logo>
                </div>
              </div>

              <i class="icon icon-chevronDown"></i>
            </div>

            <div
              aria-labelledby="profileDropdownToggle"
              class="header__content-right-profile-dropdown-menu-container"
              ngbDropdownMenu
            >
              <app-profile-dropdown-menu
                (currentConnectedUserChange)="setCurrentConnectedUser($event)"
              ></app-profile-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
