<div class="auth-content">
  <div class="auth-page-header">
    <img
      alt="logo"
      appAssetSrc="assets/svg/common/logo.svg"
    />
    <app-auth-step-indicator [currentStepId]="currentStep === 1 ? 1 : 2"></app-auth-step-indicator>
  </div>

  @if (!isLoaded) {
    <loader></loader>
  }

  @if (isLoaded) {
    @if (currentStep === steps.start) {
      <div class="interview-page_centered_content interview-page_start">
        <h1>{{ 'interviewPage.welcome' | translate }}</h1>
        <p>
          {{ 'interviewPage.welcomeMsg' | translate: { senderCo: 'Sender', companyName: companyName } }}
        </p>
        <div class="interview-page_buttons">
          <div class="buttons-group">
            <button
              (click)="start()"
              [appBtnLoading]="isLoaded"
              class="btn btn-primary submit-btn"
            >
              {{ 'common.buttons.continue' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
    @if (currentStep === steps.interviewA) {
      <div class="interview-page_answers-content">
        <h1>
          {{ 'interviewPage.companyAssosiation.title' | translate: { senderCo: 'Sender', companyName: companyName } }}
        </h1>
        <p>{{ 'interviewPage.companyAssosiation.titleMsg' | translate }}</p>
        <div class="interview-page_answers">
          @for (answer of answersA; track answer) {
            <div
              (click)="selectedAnswerA = answer.id"
              [class.selected]="selectedAnswerA === answer.id"
              class="interview-page_answer"
            >
              <app-radio [(ngModel)]="selectedAnswerA"></app-radio>
              <div class="radio-label interview-page_answers_label">
                {{ answer.text | translate: { companyName: companyName } }}
              </div>
            </div>
          }
        </div>
        <div class="interview-page_buttons">
          <button
            (click)="continue()"
            [appBtnLoading]="isSendingAnswer"
            [disabled]="!selectedAnswerA"
            class="btn btn-primary submit-btn"
          >
            {{ 'common.buttons.continue' | translate }}
          </button>
          <button class="btn btn-link">
            {{ 'interviewPage.buttons.goBack' | translate }}
          </button>
        </div>
      </div>
    }
    @if (currentStep === steps.interviewB) {
      <div class="interview-page_answers-content">
        <h1>
          {{ 'interviewPage.companySelection.title' | translate: { senderCo: 'Sender', companyName: companyName } }}
        </h1>
        <p>{{ 'interviewPage.companySelection.titleMsg' | translate }}</p>
        <div class="interview-page_answers">
          @for (answer of answersB; track answer) {
            <div
              (click)="selectedAnswerB = answer.id"
              [class.selected]="selectedAnswerB === answer.id"
              class="interview-page_answer"
              class="interview-page_answer"
            >
              <img [appAssetSrc]="answer.icon" />
              <div>
                <div>
                  {{ answer.title | translate: { companyName: companyName } }}
                </div>
                <div>
                  {{ answer.description | translate }}
                </div>
              </div>
            </div>
          }
        </div>
        <div class="interview-page_buttons">
          <button
            (click)="continue()"
            [appBtnLoading]="isSendingAnswer"
            [disabled]="!selectedAnswerB"
            class="btn btn-primary submit-btn"
          >
            {{ 'common.buttons.continue' | translate }}
          </button>
          <button
            (click)="goBack()"
            class="btn btn-link"
          >
            {{ 'interviewPage.buttons.goBack' | translate }}
          </button>
        </div>
      </div>
    }
    @if (currentStep === steps.invitationDeclined) {
      <div class="interview-page_answers-content">
        <h1>
          {{ 'connectionInvitationPage.wereSorry' | translate }}
        </h1>
        <div class="interview-page_decline-message">
          <p>
            {{ 'connectionInvitationPage.thanksForResponding' | translate: { companyName: companyName } }}
          </p>
          <p>{{ 'connectionInvitationPage.canCloseTab' | translate }}</p>
        </div>
      </div>
    }
  }
</div>
