import { Component, type OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-invitation-required-page',
  templateUrl: './invitation-required-page.component.html',
  styleUrls: ['./invitation-required-page.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class InvitationRequiredPageComponent implements OnInit {
  ngOnInit() {}
}
