import { Component, type OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-enable-tfa-modal',
  templateUrl: './enable-tfa-modal.component.html',
  styleUrls: ['./enable-tfa-modal.component.scss'],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, TranslateModule],
})
export class EnableTfaModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);

  ngOnInit() {}
}
