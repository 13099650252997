export enum AppRoutes {
  Dashboard = 'dashboard',
  Network = 'network',
  NetworkV2 = 'network-v2',
  Tasks = 'tasks',
  Files = 'files',
  Settings = 'settings',
  Campaigns = 'campaigns',
  Workflows = 'workflows',
  Conversations = 'conversations',
  ConversationsBase = 'conversations-v4',
  Products = 'products',
}
