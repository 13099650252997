<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="invitation-inactive-page_content">
    <img appAssetSrc="assets/svg/auth/invitation-expired.svg" />
    <h1>{{ 'invitationAlreadyAcceptedPage.title' | translate }}</h1>
    <p>
      <span>{{ 'invitationAlreadyAcceptedPage.invitationAcceptedMsg' | translate }}</span>
      <a [routerLink]="['/login']">
        {{ 'invitationAlreadyAcceptedPage.login' | translate }}
      </a>
    </p>
  </div>
</div>
