import { Component, type OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService } from '@core/services/user.service';

import { AuthService } from '../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-auth-social-signup',
  templateUrl: './social-signup.component.html',
  styleUrls: ['./social-signup.component.scss'],
  standalone: true,
})
export class AuthSocialSignupComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    if (params.userId) {
      this.authService
        .finishSocialAuth()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.userService.login(res);
        });
    } else {
      this.authService.googleSignupData = {
        firstName: params.firstName,
        lastName: params.lastName,
      };
      this.router.navigate(['/googleSignup']);
    }
  }
}
