import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-invitation-expired',
  templateUrl: './invitation-expired.component.html',
  styleUrls: ['./invitation-expired.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class InvitationExpiredComponent {}
