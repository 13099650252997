<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="email-sent-msg">
    <img appAssetSrc="assets/svg/common/email-sent.svg" />
    <h1>{{ 'emailSent.pleaseVerify' | translate }}</h1>
    <h3>{{ 'emailSent.verifyEmail' | translate }}</h3>
    <p>
      {{ 'emailSent.didntReceiveEmail' | translate }}
      <a
        href="#"
        (click)="resendEmail($event)"
        >{{ 'emailSent.clickHere' | translate }}</a
      >
    </p>
  </div>
</div>
