<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <form
    [formGroup]="form"
    class="login-form"
    #ngForm="ngForm"
    (ngSubmit)="resetPass()"
  >
    <img
      appAssetSrc="assets/svg/common/password-reset.svg"
      alt="logo"
    />

    <h1 class="reset-password__title">{{ 'passwordReset.resetPassword' | translate }}</h1>
    <h3>{{ 'passwordReset.provideEmail' | translate }}</h3>

    <div
      class="form-group mb-0"
      [ngClass]="{ 'has-error': ngForm.submitted && form.get('email').invalid }"
    >
      <label
        class="required"
        for="email"
        >{{ 'passwordReset.controls.email.label' | translate }}</label
      >
      <input
        type="email"
        formControlName="email"
        id="email"
      />
      <form-error-messages
        [showErrors]="ngForm.submitted"
        [control]="form.get('email')"
      >
      </form-error-messages>
    </div>

    <button
      type="submit"
      [appBtnLoading]="isLoading"
      [disabled]="ngForm.submitted && form.invalid"
      class="btn btn-primary submit-btn submit-btn-wide"
    >
      {{ 'common.buttons.confirm' | translate }}
    </button>
  </form>
</div>
