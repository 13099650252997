import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard';

import { CompanyAccessRequestEmailConfirmation } from './company-access-request-email-confirmation/company-access-request-email-confirmation.component';
import { ConnectionInvitationPageComponent } from './connection-invitation-page/connection-invitation-page.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { EmailSentComponent } from './email-sent/email-sent.component';
import { EmailConfirmTokenExpiredComponent } from './error-pages/email-confirm-token-expired/email-confirm-token-expired.component';
import { EmailNotAuthorizedPageComponent } from './error-pages/email-not-authorized-page/email-not-authorized-page.component';
import { InvitationAlreadyAcceptedPageComponent } from './error-pages/invitation-already-accepted-page/invitation-already-accepted-page.component';
import { InvitationExpiredComponent } from './error-pages/invitation-expired/invitation-expired.component';
import { InvitationInactivePageComponent } from './error-pages/invitation-inactive-page/invitation-inactive-page.component';
import { InvitationRequiredPageComponent } from './error-pages/invitation-required-page/invitation-required-page.component';
import { MaintenancePageComponent } from './error-pages/maintenance-page/maintenance-page.component';
import { NotFoundPageComponent } from './error-pages/not-found-page/not-found-page.component';
import { PassTokenExpiredComponent } from './error-pages/pass-token-expired/pass-token-expired.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetSentComponent } from './password-reset-sent/password-reset-sent.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthSigninComponent } from './signin/signin.component';
import { AuthSignupComponent } from './signup/signup.component';
import { CompanySelectComponent } from './signup-steps/company-select/company-select.component';
import { CompanyTypeSelectComponent } from './signup-steps/company-type-select/company-type-select.component';
import { InterviewComponent } from './signup-steps/interview/interview.component';
import { InvitationSignupComponent } from './signup-steps/invitation-signup/invitation-signup.component';
import { ProfileSetupComponent } from './signup-steps/profile-setup/profile-setup.component';
import { AuthSocialSignupComponent } from './social-signup/social-signup.component';

const routes: Routes = [
  {
    path: 'signup',
    component: AuthSignupComponent,
  },
  {
    path: 'login',
    component: AuthSigninComponent,
  },
  {
    path: 'socialSignup',
    component: AuthSocialSignupComponent,
  },
  {
    path: 'googleSignup',
    component: AuthSignupComponent,
  },
  {
    path: 'emailSent',
    component: EmailSentComponent,
  },
  {
    path: 'companyInvitation',
    component: ConnectionInvitationPageComponent,
  },
  {
    path: 'companyInvitationDeclined',
    component: ConnectionInvitationPageComponent,
    data: {
      declined: true,
    },
  },
  {
    path: 'companyInvitationPreview',
    component: ConnectionInvitationPageComponent,
    data: {
      preview: true,
    },
  },
  {
    path: 'emailConfirmation',
    component: EmailConfirmationComponent,
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'passwordResetSent',
    component: PasswordResetSentComponent,
  },
  {
    path: 'invitationInactive',
    component: InvitationInactivePageComponent,
  },
  {
    path: 'invitationSignup',
    component: InvitationSignupComponent,
  },
  {
    path: 'companyTypeSelection',
    component: CompanyTypeSelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'companySelection',
    component: CompanySelectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profileSetup',
    component: ProfileSetupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'interview',
    component: InterviewComponent,
  },
  {
    path: 'invitationRequired',
    component: InvitationRequiredPageComponent,
  },
  {
    path: 'emailNotAuthorized',
    component: EmailNotAuthorizedPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'passwordResetLinkExpired',
    component: PassTokenExpiredComponent,
  },
  {
    path: 'emailConfirmationLinkExpired',
    component: EmailConfirmTokenExpiredComponent,
  },
  {
    path: 'invitationExpired',
    component: InvitationExpiredComponent,
  },
  {
    path: 'invitationAlreadyAccepted',
    component: InvitationAlreadyAcceptedPageComponent,
  },
  {
    path: 'companyAccessRequestEmailConfirmation',
    component: CompanyAccessRequestEmailConfirmation,
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent,
  },
  {
    path: 'pageNotFound',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
