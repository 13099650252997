import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, type OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PAGE_URL } from '@core/constants/page-url';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { AuthService, UserManagementErrorCodes } from '../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
  standalone: true,
})
export class EmailConfirmationComponent implements OnInit {
  private readonly auth = inject(AuthService);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly toastr = inject(ToastrService);
  private readonly translate = inject(TranslateService);
  private readonly userService = inject(UserService);

  public ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams.token;
    if (!token) {
      this.router.navigate([PAGE_URL.LOGIN]);
      return;
    }

    this.auth.emailConfirmationToken = token;
    this.auth
      .confirmEmail(token)
      .pipe(
        untilDestroyed(this),
        switchMap((res) => {
          return this.translate.get('signUp.messages.emailConfirmed').pipe(
            map(async (translation) => {
              this.toastr.success(translation);
              await this.userService.login(res);
            }),
          );
        }),
        catchError(async (err: HttpErrorResponse) => {
          const errorCodes = (err.error?.errors || []).map((error: { errorCode: string }) => error?.errorCode);

          if (errorCodes.includes(UserManagementErrorCodes.EmailIsAlreadyConfirmed)) {
            await this.router.navigate(['/login']);
            return of(null);
          }

          await this.router.navigate(['/emailConfirmationLinkExpired']);
          return of(err);
        }),
      )
      .subscribe();
  }
}
