<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="password-reset-msg">
    <img appAssetSrc="assets/svg/auth/check-email.svg" />
    <h1>{{ 'passwordReset.checkEmail' | translate }}</h1>
    <div>{{ 'passwordReset.resetLinkSent' | translate }}</div>
  </div>
</div>
