import { Component, type OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ToastrService } from '@core/services/toastr.service';

import { AuthService } from '../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class EmailSentComponent implements OnInit {
  private readonly auth = inject(AuthService);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastrService);
  public isLoading = false;

  ngOnInit(): void {
    if (!this.auth.lastRegisteredEmail) {
      this.router.navigate(['/signup']);
    }
  }

  public resendEmail(event: Event): void {
    event.preventDefault();
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.auth
      .resendEmail(this.auth.lastRegisteredEmail)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.toastr.success('emailSent.messages.resendSuccess');
      });
  }
}
