export enum FirstQuestionAnswerTypes {
  Worker = 'WorkDirectly',
  Broker = 'WorkAsBrokerage',
  NotRelated = 'DoNotRelated',
}

export const FirstQuestionAnswers = [
  {
    id: FirstQuestionAnswerTypes.Worker,
    text: 'interviewPage.companyAssosiation.answers.worker',
  },
  {
    id: FirstQuestionAnswerTypes.Broker,
    text: 'interviewPage.companyAssosiation.answers.broker',
  },
  {
    id: FirstQuestionAnswerTypes.NotRelated,
    text: 'interviewPage.companyAssosiation.answers.noRelationship',
  },
];

export enum SecondQuestionAnswerTypes {
  VendorOrManufacturer = 'VendorOrManufacturer',
  Brokerage = 'Brokerage',
  RetailerOrReseller = 'RetailerOrReseller',
  Distributor = 'Distributor',
}

export const SecondQuestionAnswers = [
  {
    id: SecondQuestionAnswerTypes.VendorOrManufacturer,
    icon: 'assets/svg/signup-interview/vendor.svg',
    title: 'interviewPage.companySelection.answers.vendor.title',
    description: 'interviewPage.companySelection.answers.vendor.description',
  },
  {
    id: SecondQuestionAnswerTypes.Brokerage,
    icon: 'assets/svg/signup-interview/brokerage.svg',
    title: 'interviewPage.companySelection.answers.broker.title',
    description: 'interviewPage.companySelection.answers.broker.description',
  },
  {
    id: SecondQuestionAnswerTypes.RetailerOrReseller,
    icon: 'assets/svg/signup-interview/retailer.svg',
    title: 'interviewPage.companySelection.answers.retailer.title',
    description: 'interviewPage.companySelection.answers.retailer.description',
  },
  {
    id: SecondQuestionAnswerTypes.Distributor,
    icon: 'assets/svg/signup-interview/distributor.svg',
    title: 'interviewPage.companySelection.answers.distributor.title',
    description: 'interviewPage.companySelection.answers.distributor.description',
  },
];
