<div class="auth-content">
  <div class="auth-page-header">
    <img
      alt="logo"
      appAssetSrc="assets/svg/common/logo.svg"
    />
  </div>

  @if (!isLoaded) {
    <loader></loader>
  }

  @if (isLoaded) {
    <div class="company-type-select-page_answers-content">
      <h1>{{ 'companyTypeSelect.title' | translate }}</h1>
      <p>{{ 'companyTypeSelect.titleMsg' | translate }}</p>
      <div class="company-type-select-page_answers">
        @for (type of companyTypeOptions; track type) {
          <div
            (click)="selectedType = type.id"
            [class.selected]="selectedType === type.id"
            class="company-type-select-page_answer"
          >
            <img [appAssetSrc]="type.icon" />
            <div>
              <div>
                {{ type.title | translate: { companyName: 'Test' } }}
              </div>
              <div>
                {{ type.description | translate }}
              </div>
            </div>
          </div>
        }
      </div>
      <div class="company-type-select-page_buttons">
        <button
          (click)="continue()"
          [appBtnLoading]="isSendingAnswer"
          [disabled]="!selectedType"
          class="btn btn-primary submit-btn"
        >
          {{ 'common.buttons.continue' | translate }}
        </button>
      </div>
    </div>
  }
</div>
