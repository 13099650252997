import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserService } from '@core/services/user.service';

interface RouteMapping {
  from: RegExp;
  to: string;
}

const V2_TO_V1_MAPPINGS: RouteMapping[] = [
  { from: /\/network-v2\/?$/, to: '/network' },
  { from: /\/network-v2\/accounts\/?$/, to: '/network/connections' },
  { from: /\/network-v2\/lists\/?$/, to: '/network/companyLists' },
  { from: /\/network-v2\/invites\/sent\/?$/, to: '/network/invitationsSent' },
  { from: /\/network-v2\/invites\/received\/?$/, to: '/network/invitationsReceived' },
  { from: /\/network-v2\/company\/(\w+)\/?.*$/, to: '/network/company/$1' },
];

const V1_TO_V2_MAPPINGS: RouteMapping[] = [
  { from: /\/network\/?$/, to: '/network-v2' },
  { from: /\/network\/connections\/?$/, to: '/network-v2/accounts' },
  { from: /\/network\/companyLists\/?$/, to: '/network-v2/lists' },
  { from: /\/network\/invitationsSent\/?$/, to: '/network-v2/invites/sent' },
  { from: /\/network\/invitationsReceived\/?$/, to: '/network-v2/invites/received' },
  { from: /\/network\/company\/(\w+)\/?.*$/, to: '/network-v2/company/$1' },
];

@Injectable({
  providedIn: 'root',
})
export class NetworkV2Redirector implements CanActivate {
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const v2Allowed = this.userService.permissions?.['Company_ViewCompanyProfileV2'];

    // Redirect to v2 if the user allowed to access it, but opened a v1 URL.
    if (v2Allowed && this.isV1Url(state.url)) {
      const mappedRoute = this.mapUrl(V1_TO_V2_MAPPINGS, state.url, '/network-v2');
      return this.router.parseUrl(mappedRoute);
    }

    // Redirect to v1 if the user trying to access v2, but not allowed.
    if (!v2Allowed && this.isV2Url(state.url)) {
      const mappedRoute = this.mapUrl(V2_TO_V1_MAPPINGS, state.url, '/network');
      return this.router.parseUrl(mappedRoute);
    }

    return true;
  }

  private mapUrl(mappings: RouteMapping[], url: string, defaultUrl: string): string {
    for (const mapping of mappings) {
      if (mapping.from.test(url)) {
        return url.replace(mapping.from, mapping.to);
      }
    }

    return defaultUrl;
  }

  private isV1Url(url: string): boolean {
    return url.includes('/network') && !url.includes('/network-v2');
  }

  private isV2Url(url: string): boolean {
    return url.includes('/network-v2');
  }
}
