import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { GoogleUtilsService } from '@core/services/google-utils.service';

import { AuthService } from '../../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-tfa-modal',
  templateUrl: './tfa-modal.component.html',
  styleUrls: ['./tfa-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class TfaModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly authService = inject(AuthService);
  private readonly googleUtils = inject(GoogleUtilsService);
  @Input() data: { phone: string; token: string };
  public isAuthFailed = false;
  public tfaForm: UntypedFormGroup;
  public isLoading = false;
  public maskedPhone: string;

  ngOnInit(): void {
    this.tfaForm = new UntypedFormGroup({
      code: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    });

    try {
      const phone = this.googleUtils.parsePhone(this.data.phone);
      this.maskedPhone = `+${phone.getCountryCode()}-***-***-${phone.getNationalNumber().toString().slice(7)}`;
    } catch (err) {
      this.maskedPhone = `${this.data.phone.slice(0, 3)}-***-***-${this.data.phone.slice(-4)}`;
    }
  }

  public signin(): void {
    if (this.tfaForm.invalid) {
      return;
    }
    this.isLoading = true;

    this.authService
      .confirmCode({ code: this.tfaForm.value.code, token: this.data.token })
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.isAuthFailed = true;
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((res) => {
        this.activeModal.close(res);
      });
  }

  public resendCode(): void {
    this.isLoading = true;
    this.authService
      .resendCode(this.data.token)
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          console.log(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.tfaForm.reset();
        this.isAuthFailed = false;
      });
  }
}
