@if (isLoaded) {
  <div class="auth-content">
    <div class="auth-page-header">
      <img
        appAssetSrc="assets/svg/common/logo.svg"
        alt="logo"
      />
    </div>
    <div class="invitation-inactive-page_content">
      <h1>
        {{ 'emailConfirmation.linkExpired' | translate }}
      </h1>
      <p>
        <a
          href="#"
          (click)="resendEmail($event)"
        >
          {{ 'emailConfirmation.clickToResend' | translate }}
        </a>
      </p>
      <img appAssetSrc="assets/svg/common/warning.svg" />
    </div>
  </div>
}
