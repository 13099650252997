import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { PAGE_URL } from '@core/constants/page-url';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type CommunicationRole } from '@core/models/communication-role';
import { RegistrationStatuses, type UploadedImageData } from '@core/models/user';
import { EnumService } from '@core/services/enum.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { CommunicationRolesComponent } from '../../../core/components/communication-roles/communication-roles.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { ImageUploaderComponent } from '../../../core/components/image-uploader/image-uploader.component';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { AuthService } from '../../auth.service';
import { EnableTfaModalComponent } from '../../modals/enable-tfa-modal/enable-tfa-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-profile-setup',
  templateUrl: './profile-setup.component.html',
  styleUrls: ['./profile-setup.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    ImageUploaderComponent,
    FormErrorMessagesComponent,
    TooltipComponent,
    CommunicationRolesComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class ProfileSetupComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastrService);
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  private readonly enumService = inject(EnumService);
  public profileForm: UntypedFormGroup;
  public logo: UploadedImageData;
  public isLoaded = false;
  public isLoading = false;
  public communicationRoles: CommunicationRole[] = [];

  public isProfileAlreadyComplete = false;
  public formSubmitted = false;

  constructor() {
    this.profileForm = new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required]),
      communicationRoles: new UntypedFormControl([]),
    });
  }

  public ngOnInit(): void {
    const user = this.userService.userProfile;
    if (user.registrationStatus === RegistrationStatuses.completed) {
      this.router.navigate(['/']);
      return;
    }

    if (user.registrationStatus === RegistrationStatuses.businessCompanyAssociated) {
      this.isProfileAlreadyComplete = true;
    }

    this.enumService
      .getCommunicationRoles()
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoaded = true)),
      )
      .subscribe((roles) => {
        this.communicationRoles = roles.filter(
          (role) => role.key !== 'GeneralManagement' && role.key !== 'AcceptConnectionInvitations',
        );
      });
  }

  public continue(): void {
    this.formSubmitted = true;
    if (this.profileForm.invalid || !this.logo) {
      return;
    }

    const form = this.profileForm.value;

    this.isLoading = true;
    this.authService
      .completeProfile({
        title: form.title,
        logo: this.logo,
        communicationRoles: form.communicationRoles,
      })
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((res) => {
        this.userService.refreshAuthToken().then(() => {
          window.location.reload();
        });
      });
  }

  public openTfaModal(): void {
    this.modalService
      .open({
        content: EnableTfaModalComponent,
      })
      .result.then(() => {
        this.router.navigate([PAGE_URL.PROFILE_SETUP]);
      })
      .catch(() => {
        this.router.navigate([PAGE_URL.LOGIN]);
      });
  }

  public onImageChange(data: UploadedImageData) {
    this.logo = data;
  }

  public completeSignup(): void {
    this.formSubmitted = true;
    if (!this.logo) {
      return;
    }

    this.isLoading = true;
    this.authService
      .completeBusinessSignup({ logo: this.logo })
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.userService.refreshAuthToken().then(() => {
            window.location.reload();
          });
        }),
        catchError((err) => {
          this.isLoading = false;
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
      )
      .subscribe();
  }
}
