import { NgClass } from '@angular/common';
import { Component, type OnInit, inject, OnDestroy } from '@angular/core';
import {
  type AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map, takeWhile } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type LoginData } from '@core/models/loginData';
import { type ServerError } from '@core/models/serverError';
import { ConfigService } from '@core/services/config.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { FormErrorMessagesComponent } from '../../core/components/form-error-messages/form-error-messages.component';
import { AuthService } from '../auth.service';
import { TermsModalComponent } from '../modals/terms-modal/terms-modal.component';
import { TfaModalComponent } from '../modals/tfa-modal/tfa-modal.component';

export const ERROR_CODES = {
  tfa: 'two_factor_required',
  invalidCres: 'invalid_email_or_password',
};

@UntilDestroy()
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    RouterLink,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class AuthSigninComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  private readonly toastr = inject(ToastrService);
  public signinForm: UntypedFormGroup;
  public isLoading = false;
  public emailControl: AbstractControl;
  public passControl: AbstractControl;
  public showResendEmail = false;
  private isAlive = true;

  public get isTimedOut(): boolean {
    return this.userService.isTimedOut;
  }

  public ngOnInit(): void {
    this.signinForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
    });

    this.passControl = this.signinForm.get('password');
    this.emailControl = this.signinForm.get('email');

    this.emailControl.valueChanges.pipe(takeWhile((_) => this.isAlive)).subscribe(() => {
      this.showResendEmail = false;
    });

    this.passControl.valueChanges.pipe(takeWhile((_) => this.isAlive)).subscribe(() => {
      if (this.emailControl.errors?.invalid_email_or_password) {
        this.emailControl.setValue(this.emailControl.value);
      }
    });
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  public signinSocial(): void {
    this.authService
      .googleSignin()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res && res.redirectUrl) {
          window.location.href = res.redirectUrl;
        }
      });
  }

  public signin(): void {
    if (this.signinForm.invalid) {
      return;
    }

    this.isLoading = true;
    const form = this.signinForm.value;
    this.authService
      .signin({ username: form.email, password: form.password })
      .pipe(
        map((res) => {
          this.handleLoginSuccess(res);
        }),
        catchError((err) => {
          this.isLoading = false;
          if (err?.errors?.length) {
            this.toastr.displayServerErrors(err);
            return;
          }
          err.error.errors.forEach((err: ServerError) => {
            if (err.property === 'general') {
              this.handleGeneralError(err);
            } else {
              const field = this.signinForm.get(err.property);
              if (field) {
                field.setErrors({
                  ...field.errors,
                  [err.errorCode]: err.errorMessage,
                });
              }
              if (err.errorCode === 'email_not_confirmed') {
                this.showResendEmail = true;
              }
            }
          });

          return of(err);
        }),
      )
      .subscribe();
  }

  public resendEmail(event: Event): void {
    event.preventDefault();
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.authService
      .resendEmail(this.signinForm.value.email)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(() => {
        this.toastr.success('emailSent.messages.resendSuccess');
      });
  }

  private handleLoginSuccess(tokenData: LoginData): void {
    const userData = tokenData.userInfo;
    if (
      !userData.termOfUseAcceptDate ||
      new Date(userData.termOfUseAcceptDate) < new Date(ConfigService.settings.touUpdateDate)
    ) {
      this.modalService
        .open({
          content: TermsModalComponent,
          options: {
            size: 'xl',
          },
          inputs: {
            authToken: tokenData.token,
          },
        })
        .result.then(() => {
          this.userService.login(tokenData);
        })
        .catch(() => {
          this.isLoading = false;
        });
    } else {
      this.userService.login(tokenData);
    }
  }

  private handleGeneralError(error: ServerError): void {
    if (error.errorCode === ERROR_CODES.invalidCres) {
      this.emailControl.setErrors({
        [error.errorCode]: error.errorMessage,
      });
    }

    if (error.errorCode === ERROR_CODES.tfa) {
      this.modalService
        .open({
          content: TfaModalComponent,
          inputs: {
            data: error.data,
          },
        })
        .result.then((res) => {
          this.handleLoginSuccess(res);
        });
    }
  }
}
