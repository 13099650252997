@if (isLoaded) {
  <div class="auth-content">
    <div class="auth-page-header">
      <img
        appAssetSrc="assets/svg/common/logo.svg"
        alt="logo"
      />
    </div>
    <!-- Pass reset form -->
    @if (!isPasswordReset) {
      <form
        autocomplete="off"
        #ngForm="ngForm"
        [formGroup]="form"
        (ngSubmit)="resetPassword()"
      >
        <h1>{{ 'passwordReset.resetYourPass' | translate }}</h1>
        <p>{{ 'passwordReset.choosePass' | translate }}</p>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': ngForm.submitted && form.get('password').invalid,
          }"
        >
          <label
            class="required"
            for="newPassword"
          >
            {{ 'profileSettings.controls.newPassword.label' | translate }}
          </label>
          <app-password-input
            formControlName="password"
            placeholder="profileSettings.controls.newPassword.placeholder"
          >
          </app-password-input>
        </div>
        <button
          [appBtnLoading]="isLoading"
          [disabled]="ngForm.submitted && form.invalid"
          type="submit"
          class="btn btn-primary submit-btn"
        >
          {{ 'passwordReset.buttons.resetPassword' | translate }}
        </button>
      </form>
    }
    <!--  -->
    <!-- Reset success screen -->
    @if (isPasswordReset) {
      <div class="reset-password_success">
        <img appAssetSrc="assets/svg/common/success.svg" />
        <h1>{{ 'passwordReset.success' | translate }}</h1>
        <p>{{ 'passwordReset.passSuccessfullyReset' | translate }}</p>
        <button
          [routerLink]="['/login']"
          class="btn btn-primary submit-btn"
        >
          {{ 'passwordReset.buttons.backToSignIn' | translate }}
        </button>
      </div>
    }
    <!--  -->
  </div>
}
