import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, HostListener, NgZone, type OnInit, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActivationEnd,
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { addDays, differenceInDays, isAfter, isBefore } from 'date-fns';
import { NzContentComponent, NzHeaderComponent, NzLayoutComponent, NzSiderComponent } from 'ng-zorro-antd/layout';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeWhile } from 'rxjs/operators';

import { HeaderComponent } from '@core/components/header/header.component';
import { MobileInterstitialType } from '@core/components/mobile-interstitial/mobile-interstitial';
import { MobileInterstitialComponent } from '@core/components/mobile-interstitial/mobile-interstitial.component';
import { NavComponent } from '@core/components/nav/nav.component';
import { PAGE_URL } from '@core/constants/page-url';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { NAVIGATOR, WINDOW } from '@core/helpers/global-objects';
import { isMobileOrTablet } from '@core/helpers/is-mobile-or-tablet';
import { type Company } from '@core/models/company';
import { RegistrationStatuses, type User } from '@core/models/user';
import { AutoLogoutService } from '@core/services/auto-logout.service';
import { ClarityMonitoringService } from '@core/services/clarity-monitoring-service';
import { CompanySubscriptionPortalService } from '@core/services/company-subscription-portal.service';
import { CompanySubscriptionService } from '@core/services/company-subscription.service';
import { ConfigService } from '@core/services/config.service';
import { HelpersService } from '@core/services/helpers.service';
import { HistoryService } from '@core/services/history-service.service';
import { LocalizationService } from '@core/services/localization.service';
import { LocalStorageService } from '@core/services/persistance.service';
import { SignalrService } from '@core/services/signalr.service';
import { UserService } from '@core/services/user.service';

const ROUTES_WITHOUT_NAV = [
  'login',
  'signup',
  'socialSignup',
  'googleSignup',
  'emailSent',
  'emailConfirmation',
  'companySelection',
  'profileSetup',
  'workflows/create',
  'workflows/edit',
  'pageNotFound',
  'companyInvitation',
  'tasks/view',
  'resetPassword',
  'error',
  'invitationExpired',
  'passwordResetLinkExpired',
  'emailConfirmationLinkExpired',
  'invitationInactive',
  'maintenance',
  'interview',
  'invitationSignup',
  'emailNotAuthorized',
];

const ROUTES_WITHOUT_HEADER = [
  'login',
  'signup',
  'socialSignup',
  'googleSignup',
  'emailSent',
  'emailConfirmation',
  'companySelection',
  'profileSetup',
  'pageNotFound',
  'companyInvitation',
  'resetPassword',
  'error',
  'invitationExpired',
  'passwordResetLinkExpired',
  'emailConfirmationLinkExpired',
  'invitationInactive',
  'maintenance',
  'interview',
  'invitationSignup',
  'emailNotAuthorized',
];

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    NzLayoutComponent,
    NzHeaderComponent,
    HeaderComponent,
    NzContentComponent,
    NgClass,
    MobileInterstitialComponent,
    TranslateModule,
    NzSiderComponent,
    NavComponent,
    FocusableDirective,
    AssetSrcDirective,
  ],
  animations: [
    trigger('loaderFade', [
      transition(':enter', [
        group([
          style({ opacity: 0 }),
          animate('200ms ease-in', style({ opacity: 1 })),
          query('.app-loader-overlay__content-icon', [
            style({ scale: 0.9 }),
            animate('200ms ease-in', style({ scale: 1 })),
          ]),
        ]),
      ]),
      transition(':leave', [
        group([
          style({ opacity: 1 }),
          animate('200ms ease-out', style({ opacity: 0 })),
          query('.app-loader-overlay__content-icon', [
            style({ scale: 1 }),
            animate('200ms ease-out', style({ scale: 0.9 })),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  public readonly translate = inject(TranslateService);
  private readonly userService = inject(UserService);
  private readonly autoLogout = inject(AutoLogoutService);
  private readonly router = inject(Router);
  private readonly ngZone = inject(NgZone);
  private readonly signalR = inject(SignalrService);
  private readonly historyService = inject(HistoryService);
  private readonly localizationService = inject(LocalizationService);
  private readonly lsService = inject(LocalStorageService);
  private readonly clarity = inject(ClarityMonitoringService);
  private readonly helpers = inject(HelpersService);
  private readonly companySubscriptionService = inject(CompanySubscriptionService);
  private readonly companySubscriptionPortalService = inject(CompanySubscriptionPortalService);
  private readonly windowReference = inject<Window>(WINDOW);
  private readonly navigatorReference = inject<Navigator>(NAVIGATOR);
  mobileInterstitialType: MobileInterstitialType = MobileInterstitialType.None;
  isMobileInterstitialDismissed = false;
  subscriptionEndDate = null;
  protected readonly MobileInterstitialType = MobileInterstitialType;
  private readonly isAlive = true;
  private isKeyboardMode: boolean;

  private readonly hasCompletedRegistration$ = combineLatest([
    this.userService.isAuthenticated$,
    this.userService.profile$.pipe(map((profile) => profile?.registrationStatus === RegistrationStatuses.completed)),
  ]).pipe(map((conditions) => conditions.every(Boolean)));

  protected readonly isNavVisible = toSignal(
    combineLatest([
      this.hasCompletedRegistration$,
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
        distinctUntilChanged(),
        map((url) => !ROUTES_WITHOUT_NAV.some((route) => url.indexOf(route) > 0)),
      ),
    ]).pipe(map((conditions) => conditions.every(Boolean))),
  );

  protected readonly isHeaderVisible = toSignal(
    combineLatest([
      this.hasCompletedRegistration$,
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url),
        distinctUntilChanged(),
        map((url) => !ROUTES_WITHOUT_HEADER.some((route) => url.indexOf(route) > 0)),
      ),
    ]).pipe(map((conditions) => conditions.every(Boolean))),
  );

  protected readonly isChildRouteLoading = toSignal(
    this.router.events.pipe(
      // Listen to route activation events
      filter(
        (event) =>
          event instanceof ActivationStart || event instanceof ActivationEnd || event instanceof NavigationCancel,
      ),
      // Filter activation events of routes that are direct children of the root route
      // Those route's components will be rendered in the router outlet of the AppComponent
      filter((event: ActivationStart | ActivationEnd | NavigationCancel) => {
        // This usually hapens when guards block the navigation (in this case, we want to hide the loader)
        if (event instanceof NavigationCancel) return true;

        const {
          snapshot: { pathFromRoot },
        } = event;

        const CHILD_ROUTE_DEPTH = 2; // AppComponent is the root route (1st level), the child route is the 2nd level
        return pathFromRoot.length === CHILD_ROUTE_DEPTH;
      }),
      map((event) => event instanceof ActivationStart),
      startWith(true),
      debounceTime(100),
    ),
  );

  constructor() {
    this.historyService.init();
    this.clarity.init();
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.localizationService.init();
  }

  public get isScrollbarDisabled(): boolean {
    return document.body.classList.contains('is-dragging-element');
  }

  public get isRepresentationMode(): boolean {
    return this.userService.isSwitchedProfile;
  }

  public get expireStatus(): string {
    return this.subscriptionExpiring ? 'expiring' : 'expired';
  }

  public get daysToExpire(): number {
    return differenceInDays(new Date(this.subscriptionEndDate), new Date());
  }

  public get isImpersonating(): boolean {
    return this.userService.isImpersonating;
  }

  public get impersonatedUser(): User {
    return this.userService.userProfile;
  }

  public get impersonatedCompany(): Company {
    return this.userService.userCompany;
  }

  get isAuthenticated(): boolean {
    return this.userService.isAuthenticated;
  }

  get showFreeTrialNotification(): boolean {
    return (
      this.userService.isAuthenticated &&
      (this.subscriptionExpiring || this.subscriptionExpired) &&
      this.notDayDismissed &&
      this.hasCompanySubscriptionInfoViewPermission
    );
  }

  get subscriptionExpiring(): boolean {
    const subscriptionEndDate = new Date(this.subscriptionEndDate);
    return isAfter(subscriptionEndDate, new Date()) && differenceInDays(subscriptionEndDate, new Date()) <= 10;
  }

  get subscriptionExpired(): boolean {
    if (this.subscriptionEndDate) {
      const subscriptionEndDate = new Date(this.subscriptionEndDate);
      return isBefore(subscriptionEndDate, new Date());
    }
    return false;
  }

  get notDayDismissed(): boolean {
    if (!this.helpers.getCookie(`subscriptionNotificationDismissedDate-${this.userService.userProfile.id}`)) {
      return true;
    }
    const dismissedDate = new Date(
      this.helpers.getCookie(`subscriptionNotificationDismissedDate-${this.userService.userProfile.id}`),
    );
    return isBefore(addDays(dismissedDate, 1), new Date());
  }

  get hasCompanySubscriptionInfoViewPermission(): boolean {
    return (
      this.userService.permissions.Company_SubscriptionInfo_View && this.userService.userProfile.isBillingAdministrator
    );
  }

  public ngOnInit(): void {
    this.userService.tryLoginWithSavedToken();
    this.ngZone.runOutsideAngular(() => {
      this.initAppInsight();
    });

    this.updateMobileInterstitialType();

    // init events for keyboard navigation track
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.code === 'Tab' && !this.isKeyboardMode) {
        this.isKeyboardMode = true;
        document.body.classList.add('keyboard-mode');
      }
    });

    document.addEventListener('mousedown', () => {
      if (this.isKeyboardMode) {
        this.isKeyboardMode = false;
        document.body.classList.remove('keyboard-mode');
      }
    });

    // track changes to token and refresh signalR connections
    this.lsService.tokenRefreshed$.pipe(takeWhile(() => this.isAlive)).subscribe(() => {
      if (this.userService.isAuthenticated) {
        this.ngZone.runOutsideAngular(() => {
          this.signalR.initConnections();
        });
      }
    });

    // track login/logout
    this.userService.isAuthenticated$.pipe(takeWhile(() => this.isAlive)).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        if (!this.signalR.isOpen) {
          this.ngZone.runOutsideAngular(() => {
            this.signalR.initConnections();
          });
        }

        this.clarity.setUserInfo(this.userService.userProfile);
        this.autoLogout.init();
      } else {
        this.signalR.closeConnections();
        this.autoLogout.stop();
        this.subscriptionEndDate = null;
      }
    });

    // redirect nav errors
    this.router.events.pipe(takeWhile(() => this.isAlive)).subscribe((event) => {
      if (
        (this.userService.userProfile?.registrationStatus === RegistrationStatuses.companyAssosiated ||
          this.userService.userProfile?.registrationStatus === RegistrationStatuses.businessCompanyAssociated) &&
        event instanceof NavigationStart &&
        event.url !== PAGE_URL.PROFILE_SETUP
      ) {
        this.router.navigate([PAGE_URL.PROFILE_SETUP]);
        return;
      }
      if (event instanceof NavigationError) {
        this.router.navigate([PAGE_URL.PAGE_NOT_FOUND]);
      }
    });
  }

  public dismissSubscriptionlNotification(): void {
    document.cookie = `subscriptionNotificationDismissedDate-${this.userService.userProfile.id}=${new Date()}`;
  }

  public notificationClicked(): void {
    this.companySubscriptionPortalService
      .getCompanySubscriptionPortal({ returnUrl: window.location.href })
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        window.open(response.url, '_blank');
      });
  }

  public exitImpersonation(): void {
    this.userService.exitImpersonation();
  }

  dismissMobileInterstitial(): void {
    this.isMobileInterstitialDismissed = true;
    this.mobileInterstitialType = MobileInterstitialType.None;
  }

  @HostListener('window:resize')
  handleWindowResize(): void {
    if (!this.isMobileInterstitialDismissed) {
      this.updateMobileInterstitialType();
    }
  }

  private updateMobileInterstitialType(): void {
    const isWindowSmall = this.windowReference.innerWidth < 580;
    const isPhoneOrTablet = isMobileOrTablet(this.navigatorReference.userAgent || this.navigatorReference.vendor);

    if (isPhoneOrTablet) {
      this.mobileInterstitialType = MobileInterstitialType.PhoneOrTablet;
      return;
    }

    if (isWindowSmall) {
      this.mobileInterstitialType = MobileInterstitialType.SmallWindow;
      return;
    }

    this.mobileInterstitialType = MobileInterstitialType.None;
  }

  private initAppInsight(): void {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: ConfigService.settings.appInsightKey,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
}
