<scrollable-area>
  @if (isLoading) {
    <loader></loader>
  }

  @if (!isLoading) {
    <div
      [style.backgroundColor]="mainColor"
      class="connection-invitation-page_sent-by"
    >
      <div>{{ 'connectionInvitationPage.sentBy' | translate }}:</div>
      @if (senderLogoUrl) {
        <img
          [src]="senderLogoUrl"
          alt=""
        />
      }
      <div>{{ senderData?.firstName }} {{ senderData?.lastName }}</div>
      <div>{{ senderData?.email || senderData?.workEmail }}</div>
    </div>
    @if (!isConnectionDeclined) {
      <div class="connection-invitation-page_message">
        <company-logo
          [company]="senderCompanyData"
          class="connection-invitation-page_company-logo"
        ></company-logo>
        <h1 class="connection-invitation-page__title">
          {{ headerText }}
        </h1>
        <rich-text-editor
          [(ngModel)]="bodyText"
          [readOnly]="true"
          class="connection-invitation-page_text-editor"
        >
        </rich-text-editor>
        <div class="connection-invitation-page_buttons">
          <button
            (click)="signUp()"
            [style.backgroundColor]="mainColor"
            [style.borderColor]="mainColor"
            class="btn btn-primary submit-btn"
          >
            {{ 'common.buttons.signUp' | translate }}
          </button>
        </div>
        <a
          [routerLink]="['/login']"
          [style.color]="mainColor"
          class="connection-invitation-page_sign-in-link"
        >
          {{ 'signUp.alreadyHaveAcc' | translate }}
          {{ 'common.buttons.signIn' | translate }}
        </a>
      </div>
    }
    @if (isConnectionDeclined) {
      <div class="connection-invitation-page_message">
        <company-logo
          [company]="senderCompanyData"
          class="connection-invitation-page_company-logo"
        ></company-logo>
        <h1>
          {{ 'connectionInvitationPage.wereSorry' | translate }}
        </h1>
        <div class="connection-invitation-page_decline-message">
          <p>
            {{ 'connectionInvitationPage.thanksForResponding' | translate: { companyName: senderCompanyData.name } }}
          </p>
          <p>{{ 'connectionInvitationPage.canCloseTab' | translate }}</p>
        </div>
      </div>
    }
  }
</scrollable-area>
