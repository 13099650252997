<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="email-not-authorized-page">
    <img appAssetSrc="assets/svg/common/warning.svg" />
    <h1>{{ 'emailNotAuthorizedPage.hmmStrange' | translate }}</h1>
    <div>
      {{ 'emailNotAuthorizedPage.emailNotAuthorized' | translate }}
      <a href="mailto:Support@ledgerset.freshdesk.com">Support&#64;ledgerset.freshdesk.com</a>
    </div>
  </div>
</div>
