<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <div class="invitation-inactive-page_content">
    <h1>{{ 'invitationInactivePage.title' | translate }}</h1>
    <div>{{ 'invitationInactivePage.inableToSetupAccount' | translate }}</div>
    <img appAssetSrc="assets/svg/common/warning.svg" />
  </div>
</div>
