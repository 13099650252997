import { NgClass } from '@angular/common';
import { Component, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { type SelectOption } from '@core/components/select/select.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type ServerError } from '@core/models/serverError';
import { EnumService } from '@core/services/enum.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';
import { extendedPhoneValidator } from '@core/validators/google-phone.validator';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { LoaderComponent } from '../../../core/components/loader/loader.component';
import { PasswordInputComponent } from '../../../core/components/password-input/password-input.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { AuthService } from '../../auth.service';
import { FirstQuestionAnswerTypes, SecondQuestionAnswerTypes } from '../interview/interview-answers';

@UntilDestroy()
@Component({
  selector: 'app-invitation-signup',
  templateUrl: './invitation-signup.component.html',
  styleUrls: ['./invitation-signup.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    SelectComponent,
    NgxMaskDirective,
    PasswordInputComponent,
    ButtonLoadingDirective,
    RouterLink,
    TranslateModule,
  ],
})
export class InvitationSignupComponent implements OnInit {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly toastr = inject(ToastrService);
  private readonly enumService = inject(EnumService);
  private readonly userService = inject(UserService);
  public isLoaded = false;
  public isLoading = false;
  public isBrokerSignup = false;
  public isFormSubmitted = false;
  public countryCodes: SelectOption[] = [];
  public signupForm: UntypedFormGroup;
  private invitationToken: string;
  private invitationData: any;

  public ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParams;

    if (!params.invitation_token) {
      this.router.navigate(['404']);
    }

    this.countryCodes = this.enumService.getPhoneCodes();
    this.authService
      .getInvitationInfo(params.invitation_token)
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          const errorCode = err?.error?.errors?.[0]?.errorCode;
          this.authService.navigateAfterError(errorCode);
          return of(err);
        }),
        finalize(() => (this.isLoaded = true)),
      )
      .subscribe((res) => {
        this.invitationToken = params.invitation_token;
        this.invitationData = res;
        this.isBrokerSignup =
          res.firstQuestionAnswer === FirstQuestionAnswerTypes.Broker ||
          res.secondQuestionAnswer === SecondQuestionAnswerTypes.Brokerage;

        this.setupForm();
      });
  }

  private setupForm(): void {
    this.signupForm = new UntypedFormGroup(
      {
        firstName: new UntypedFormControl('', [Validators.required]),
        lastName: new UntypedFormControl('', [Validators.required]),
        countryCode: new UntypedFormControl(this.countryCodes[0].value),
        phoneNumber: new UntypedFormControl('', [Validators.required]),
        username: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required]),
        terms: new UntypedFormControl(false, [Validators.requiredTrue]),
      },
      [extendedPhoneValidator('countryCode', 'phoneNumber')],
    );

    if (this.invitationData.invitedUserEmail) {
      this.signupForm.get('username').setValue(this.invitationData.invitedUserEmail);
    }

    if (this.invitationData.invitedUserFirstName) {
      this.signupForm.get('firstName').setValue(this.invitationData.invitedUserFirstName);
    }

    if (this.invitationData.invitedUserLastName) {
      this.signupForm.get('username').setValue(this.invitationData.invitedUserLastName);
    }
  }

  public get companyName(): string {
    return this.invitationData?.senderCompany?.name;
  }

  public signup(): void {
    this.isFormSubmitted = true;

    if (this.signupForm.invalid) {
      return;
    }

    const payload = this.signupForm.value;
    payload.phoneNumber = payload.countryCode + payload.phoneNumber;
    payload.invitationToken = this.invitationToken;
    payload.username = this.invitationData.invitedUserEmail;

    this.isLoading = true;

    this.authService
      .signup(payload)
      .then((res) => {
        this.userService.login(res);
      })
      .catch((res) => this.handleServerError(res))
      .finally(() => (this.isLoading = false));
  }

  private handleServerError(response: any): void {
    if (!response.error?.errors) {
      this.toastr.error('common.errorMessages.unexpected');
      return;
    }
    response.error.errors.forEach((err: ServerError) => {
      if (err.property === 'general') {
        this.toastr.serverError(err);
        return;
      }

      if (err.errorCode === 'user_already_exists') {
        this.toastr.error('signUp.errors.alreadyAccepted');
        return;
      }

      if (err.errorCode === 'invitation_already_accepted') {
        this.toastr.error('signUp.errors.linkExpired');
        return;
      }
      if (err.errorCode === 'allowed_only_registration_via_invitation') {
        this.toastr.error('signUp.errors.allowed_only_registration_via_invitation');
        return;
      }

      let field;

      if (err.property === 'email') {
        field = this.signupForm.get('username');
      } else {
        field = this.signupForm.get(err.property);
      }
      if (field) {
        field.setErrors({ ...field.errors, [err.errorCode]: err.errorMessage });
      }
    });
  }
}
