@for (step of steps; track step) {
  <div
    class="auth-step-indicator_step"
    [class.current]="step.id === currentStepId"
    [class.past]="step.id < currentStepId"
  >
    <div>
      <div class="auth-step-indicator_step-number">
        @if (step.id < currentStepId) {
          <img appAssetSrc="assets/svg/common/checkmark-blue.svg" />
        }
        @if (step.id >= currentStepId) {
          <span>
            {{ step.id }}
          </span>
        }
      </div>
      <span class="auth-step-indicator_label">{{ step.label | translate }}</span>
    </div>
    <div class="auth-step-indicator_underline"></div>
  </div>
}
