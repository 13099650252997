<div class="modal-header">
  <button
    [appFocusable]
    type="button"
    class="close-btn"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  >
    <img
      appAssetSrc="assets/svg/common/cross.svg"
      alt="{{ 'common.buttons.close' | translate }}"
    />
  </button>
</div>

<div class="modal-body">
  <div
    class="enable-tfa"
    ngbAutofocus
  >
    <img appAssetSrc="assets/svg/auth/enable-tfa.svg" />
    <h1>{{ 'enableTfaModal.enableTfa' | translate }}</h1>
    <p>{{ 'enableTfaModal.tfaExplanation' | translate }}</p>

    <div class="buttons-group">
      <button
        class="btn decline-btn"
        (click)="activeModal.dismiss()"
      >
        {{ 'enableTfaModal.buttons.notNow' | translate }}
      </button>
      <button
        class="btn btn-primary submit-btn"
        (click)="activeModal.close()"
      >
        {{ 'enableTfaModal.buttons.enableTfa' | translate }}
      </button>
    </div>
  </div>
</div>
