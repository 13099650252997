<div class="mobile-interstitial">
  <div class="mobile-interstitial__message">
    <i
      [class.icon-resize-window]="type === MobileInterstitialType.SmallWindow"
      [class.icon-switch-to-desktop]="type === MobileInterstitialType.PhoneOrTablet"
      class="mobile-interstitial__message-icon"
    ></i>

    <div class="mobile-interstitial__message-title">
      @if (type === MobileInterstitialType.SmallWindow) {
        {{ 'mobileInterstitial.smallWindow.title' | translate }}
      }

      @if (type === MobileInterstitialType.PhoneOrTablet) {
        {{ 'mobileInterstitial.phoneOrTablet.title' | translate }}
      }
    </div>

    <div class="mobile-interstitial__message-description">
      @if (type === MobileInterstitialType.SmallWindow) {
        {{ 'mobileInterstitial.smallWindow.message' | translate }}
      }

      @if (type === MobileInterstitialType.PhoneOrTablet) {
        {{ 'mobileInterstitial.phoneOrTablet.message' | translate }}
      }
    </div>
  </div>

  <app-button
    (click)="dismiss.emit()"
    [size]="ButtonSize.Big"
    [type]="ButtonType.Primary"
  >
    {{ 'mobileInterstitial.continueAnywayButton' | translate }}
  </app-button>
</div>
