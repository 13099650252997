<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  @if (!isLoaded) {
    <loader></loader>
  }

  @if (isLoaded) {
    <form
      autocomplete="chrome-off"
      [formGroup]="signupForm"
      (ngSubmit)="signup()"
    >
      @if (!isBusinessSignup) {
        <h1>{{ 'signUp.signUpFor' | translate }}</h1>
        <h3>{{ 'signUp.accSetUp' | translate }}</h3>
      }
      @if (isBusinessSignup) {
        <h1>{{ 'signUp.welcome' | translate: { name: invitedUserFirstName } }}</h1>
        <h3>{{ 'signUp.passSetUp' | translate }}</h3>
      }
      @if (!isBusinessSignup) {
        <div class="form-row">
          <div
            class="form-group mb-3"
            [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('firstName').invalid }"
          >
            <label
              class="required"
              for="firstName"
            >
              {{ 'signUp.controls.firstName.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="firstName"
              id="firstName"
              placeholder="{{ 'signUp.controls.firstName.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="signupForm.get('firstName')"
            >
            </form-error-messages>
          </div>
          <div
            class="form-group mb-3"
            [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('lastName').invalid }"
          >
            <label
              class="required"
              for="lastName"
            >
              {{ 'signUp.controls.lastName.label' | translate }}
            </label>
            <input
              autocomplete="false"
              type="text"
              formControlName="lastName"
              id="lastName"
              placeholder="{{ 'signUp.controls.lastName.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="signupForm.get('lastName')"
            >
            </form-error-messages>
          </div>
        </div>
        @if (!isGoogleSignup) {
          <div
            class="form-group mb-3 signup-form_email-field"
            [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('username').invalid }"
          >
            <label
              class="required"
              for="email"
            >
              {{ 'signUp.controls.email.label' | translate }}
            </label>
            <input
              autocomplete="chrome-off"
              type="email"
              formControlName="username"
              id="email"
              placeholder="{{ 'signUp.controls.email.placeholder' | translate }}"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [customErrors]="{ email_contains_free_domain: 'signUp.errors.email_contains_free_domain' }"
              [control]="signupForm.get('username')"
            >
            </form-error-messages>
          </div>
        }
        <div class="form-row">
          <div
            class="form-group mb-3 country-code-control"
            [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('countryCode').invalid }"
          >
            <label
              class="required"
              for="countryCode"
            >
              {{ 'signUp.controls.countryCode.label' | translate }}
            </label>
            <app-select
              id="countryCode"
              placeholder="common.buttons.select"
              formControlName="countryCode"
              [options]="countryCodes"
            >
            </app-select>
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="signupForm.get('countryCode')"
            >
            </form-error-messages>
          </div>
          <div
            class="form-group mb-3"
            [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('phoneNumber').invalid }"
          >
            <label
              class="required"
              for="phoneNumber"
            >
              {{ 'signUp.controls.phoneNumber.label' | translate }}
            </label>
            <input
              autocomplete="chrome-off"
              type="text"
              formControlName="phoneNumber"
              id="phoneNumber"
              placeholder="{{ 'signUp.controls.phoneNumber.placeholder' | translate }}"
              mask="000-000-00000"
              [validation]="false"
            />
            <form-error-messages
              [showErrors]="isFormSubmitted"
              [control]="signupForm.get('phoneNumber')"
            >
            </form-error-messages>
          </div>
        </div>
        @if (!isGoogleSignup) {
          <div
            class="form-group mb-4"
            [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('password').invalid }"
          >
            <label class="required">
              {{ 'common.controls.password.label' | translate }}
            </label>
            <div class="password-requirement mb-1">{{ 'signUp.passwordMinLength' | translate }}</div>
            <app-password-input formControlName="password"></app-password-input>
          </div>
        }
      }
      @if (isBusinessSignup) {
        <div
          class="form-group mb-4"
          [ngClass]="{ 'has-error': isFormSubmitted && signupForm.get('password').invalid }"
        >
          <label class="required">
            {{ 'common.controls.password.label' | translate }}
          </label>
          <app-password-input formControlName="password"></app-password-input>
        </div>
      }
      <div class="form-check signup-form_terms-of-use">
        <app-checkbox formControlName="terms">
          <ng-container slot="label">
            <span>
              {{ 'signUp.iAcceptThe' | translate }}
              <a
                href="#"
                (click)="openTerms($event)"
              >
                {{ 'signUp.termsOfUse' | translate }}
              </a>
            </span>
          </ng-container>
        </app-checkbox>
      </div>
      <button
        type="submit"
        [appBtnLoading]="isLoading"
        [disabled]="!signupForm.get('terms').value || (isFormSubmitted && signupForm.invalid) || isLoading"
        class="btn btn-primary submit-btn submit-btn-wide"
      >
        {{ 'signUp.buttons.createAccount' | translate }}
      </button>
      @if (!isUserInvited) {
        <div class="signup-form_login-link">
          <span>{{ 'signUp.alreadyHaveAcc' | translate }}</span>
          <a [routerLink]="['/login']">
            {{ 'common.buttons.signIn' | translate }}
          </a>
        </div>
      }
    </form>
  }
</div>
