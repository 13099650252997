import { Component, type OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CompanyAccessRequestsService } from '@clover/company-access-requests/company-access-requests.service';
import { PAGE_URL } from '@core/constants/page-url';

@UntilDestroy()
@Component({
  selector: 'app-company-access-request-email-confirmation',
  templateUrl: './company-access-request-email-confirmation.component.html',
  standalone: true,
})
export class CompanyAccessRequestEmailConfirmation implements OnInit {
  private readonly companyAcessRequestService = inject(CompanyAccessRequestsService);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  public ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams.token;
    if (!token) {
      this.router.navigate([PAGE_URL.LOGIN]);
      return;
    }

    this.companyAcessRequestService
      .confirmEmail(token)
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.router.navigate([PAGE_URL.LOGIN]);
          return of(err);
        }),
      )
      .subscribe((res) => {
        this.router.navigate(['/settings/car'], {
          queryParams: { emailConfirmed: true },
        });
      });
  }
}
