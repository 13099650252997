<div class="oops-page_header">
  <img
    appAssetSrc="assets/svg/common/logo.svg"
    alt="logo"
  />
</div>
<div class="oops-page_content">
  <div>
    <img
      appAssetSrc="assets/svg/common/warning.svg"
      alt="logo"
    />

    <h1>{{ 'oopsPage.title' | translate }}</h1>
    <h3>{{ 'oopsPage.message' | translate }}</h3>

    <div class="oops-page_buttons">
      <button
        class="btn btn-primary submit-btn"
        (click)="redirectToDashboard()"
      >
        {{ 'notFoundPage.buttons.goToDashboard' | translate }}
      </button>
      <button
        class="btn btn-primary decline-btn"
        (click)="goToPreviousPage()"
      >
        {{ 'notFoundPage.buttons.returnToPrevPage' | translate }}
      </button>
    </div>
  </div>
</div>
