<div class="modal-header">
  <button
    (click)="activeModal.dismiss()"
    [appFocusable]
    aria-label="Close"
    class="close-btn"
    type="button"
  >
    <img
      alt="{{ 'common.buttons.close' | translate }}"
      appAssetSrc="assets/svg/common/cross.svg"
    />
  </button>
</div>

<div class="modal-body">
  <div class="terms-modal-header">
    <img appAssetSrc="assets/svg/auth/terms-icon.svg" />
    <div>
      <h1>{{ 'termsModal.termsOfUse' | translate }}</h1>
      <p>
        {{ 'common.strings.lastUpdated' | translate }}
        {{ updatedDate | date: 'MMMM dd, yyyy' }}
      </p>
    </div>
  </div>
  <div
    [innerHtml]="termsHtml"
    class="terms-modal-text"
    ngbAutofocus
  ></div>
</div>

@if (!readonly) {
  <div class="modal-footer">
    <div class="buttons-group">
      <button
        (click)="declineTerms()"
        [disabled]="isLoading"
        [appFocusable]
        class="btn btn-primary decline-btn"
      >
        {{ 'termsModal.buttons.iDecline' | translate }}
      </button>
      <button
        (click)="acceptTerms()"
        [appBtnLoading]="isLoading"
        [appFocusable]
        class="btn btn-primary submit-btn"
      >
        {{ 'termsModal.buttons.iAccept' | translate }}
      </button>
    </div>
  </div>
}
