@if (isLoaded) {
  <div class="auth-content">
    <div class="auth-page-header">
      <img
        alt="logo"
        appAssetSrc="assets/svg/common/logo.svg"
      />
    </div>
    <div class="profile-setup-container">
      <form
        (ngSubmit)="continue()"
        [formGroup]="profileForm"
        autocomplete="off"
      >
        @if (!isProfileAlreadyComplete) {
          <h1>{{ 'profileSetup.setUpProfile' | translate }}</h1>
          <h3>{{ 'profileSetup.tellUsAboutYourself' | translate }}</h3>
        }
        @if (isProfileAlreadyComplete) {
          <h1>{{ 'profileSetup.completeProfile' | translate }}</h1>
          <h3>{{ 'profileSetup.profileAlreadyCompleted' | translate }}</h3>
        }
        <div
          [ngClass]="{ 'has-error': formSubmitted && !logo }"
          class="form-group"
        >
          <label class="required">
            {{ 'profileSetup.controls.photoUploader.label' | translate }}
          </label>
          <image-uploader
            #imageUploader
            (uploadedImage)="onImageChange($event)"
            placeholderText="profileSetup.dragPhoto"
            placeholderText2="profileSetup.makeYourselfRecognizable"
          >
          </image-uploader>
          @if (formSubmitted && !logo) {
            <div class="form-control-error">
              {{ 'profileSetup.controls.photoUploader.photoRequired' | translate }}
            </div>
          }
        </div>
        @if (!isProfileAlreadyComplete) {
          <div
            [ngClass]="{
              'has-error': formSubmitted && profileForm.get('title').invalid,
            }"
            class="form-group"
          >
            <label
              class="required"
              for="title"
            >
              {{ 'profileSetup.controls.title.label' | translate }}
            </label>
            <input
              formControlName="title"
              id="title"
              placeholder="{{ 'profileSetup.controls.title.placeholder' | translate }}"
              type="text"
            />
            <form-error-messages
              [control]="profileForm.get('title')"
              [showErrors]="formSubmitted"
            ></form-error-messages>
          </div>
          <div class="form-group profile-setup_roles">
            <label>
              <span>{{ 'profileSetup.controls.role.label' | translate }}</span>
              <app-tooltip [contentTemplate]="tooltip">
                <div class="tooltip-icon"></div>
              </app-tooltip>
              <ng-template #tooltip>
                <div>{{ 'profileSetup.controls.role.tooltip' | translate }}</div>
              </ng-template>
            </label>
            <communication-roles-selector
              [compactMode]="true"
              [roles]="communicationRoles"
              formControlName="communicationRoles"
            >
            </communication-roles-selector>
          </div>
        }
        @if (!isProfileAlreadyComplete) {
          <button
            [appBtnLoading]="isLoading"
            [disabled]="formSubmitted && (profileForm.invalid || !logo)"
            class="btn btn-primary submit-btn"
            type="submit"
          >
            {{ 'common.buttons.continue' | translate }}
          </button>
        }
        @if (isProfileAlreadyComplete) {
          <button
            (click)="completeSignup()"
            [appBtnLoading]="isLoading"
            [disabled]="!logo"
            class="btn btn-primary submit-btn"
          >
            {{ 'profileSetup.buttons.completeSignup' | translate }}
          </button>
        }
      </form>
    </div>
  </div>
}
