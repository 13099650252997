import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class MaintenancePageComponent {}
